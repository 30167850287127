import React from "react";
import "./WhyZeroPage.css";

export const WhyZeroPage = () => {
    return (
        <div className="why-zero-container">
            <header className="why-zero-header">
                <h1>Why use the Zerodev AI-Enhanced approach?</h1>
            </header>
            <main className="why-zero-content">
                <section className="why-zero-intro">
                    <h2>The power of Zero uprfront costs</h2>
                    <p>
                        At Zerødev, we firmly believe that most companies could drastically improve their operations with
                        technology, especially with the now widely available AI tools. However, we understand that concerns about cost and complexity often hold businesses
                        back. That's why we've made it our mission to address these worries head-on with our AI-enabled Zero Cost Approach.
                    </p>
                    <p>
                        Our unique model aligns our success directly with yours. We carefully assess projects we believe
                        will be beneficial and share in the rewards they generate. This means we're truly on the same
                        team - we have no incentive to overcharge or underdeliver. Our success is entirely dependent on
                        your success. The idea behind is we believe we can build powerful value generating software with these
                        new AI tools, so we bring the technology and leverage it on your business making it a win-win situation.
                    </p>
                </section>
                <section className="why-zero-benefits">
                    <h2>The Benefits of Going Zero</h2>
                    <ul>
                        <li><b>Zero Financial Risk</b>: No development costs - you only pay when you see results</li>
                        <li><b>Experiment Freely:</b> Try out new AI technology integrations without fear of wasted investment</li>
                        <li><b>Aligned Interests:</b> We succeed only when you succeed, ensuring our full commitment</li>
                        <li><b>Access to Expertise</b>: Leverage our skills in Technology and AI and experience without the usual large upfront price tag</li>
                        <li><b>Focus on Results</b>: Our model naturally prioritizes outcomes over billable hours</li>
                    </ul>
                </section>
                <section className="why-zero-approach">
                    <h2>Why use embrace our approach</h2>
                    <p>
                        At Zerødev, we're eliminating financial barriers, our approach combines rapid prototyping, agile iteration, and technology-agnostic
                        solutions to deliver unparalleled value. This is all only made possible due how much recent AI advances speed up technology development.
                    </p>
                    <p>
                        We start with quick, functional demos to test your AI ideas in real-world scenarios.
                        As we progress, we continuously refine based on performance data and your
                        feedback, ensuring your solution evolves with the needs of your business or team.
                    </p>
                    <p>
                        Our profit-sharing model means we're not just your developers—we're your long-term partners,
                        committed to your ongoing success. With Zerødev, you're not just getting software; you're gaining a
                        long-term technology partner ushering you into the future of business technology.
                    </p>
                </section>
            </main>
        </div>
    );
};