import React from 'react';

const AIExplainer = () => {
    return (
        <section className="ai-explainer">
            <div className="container">
                <h2>The AI Revolution: Fast, Affordable, Powerful</h2>
                <div className="explainer-content">
                    <div className="explainer-text">
                        <h3>Software for the AI era</h3>
                        <p>
                            Since the ChatGPT and other systems are widely available, AI has become accessible
                            and affordable. We can now implement many software solutions within weeks, not years,
                            without the need for a large team of expensive AI experts.
                        </p>
                        <p>
                            AI systems can handle complex and ambiguous tasks, and can be used in thousands of
                            new use-cases that were previously impossible or infeasible due to cost.
                        </p>
                    </div>
                    <div className="explainer-benefits">
                        <h3>Benefits of Modern AI Solutions:</h3>
                        <ul>
                            <li>Rapid implementation (weeks, not years)</li>
                            <li>Cost-effective (no need for large AI teams)</li>
                            <li>Handles ambiguous and complex tasks</li>
                            <li>Thousands of new affordable use-cases</li>
                            <li>Becoming smarter, faster and cheaper at a rapid rate</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AIExplainer;