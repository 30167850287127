import React from 'react';
import { motion } from 'framer-motion';

const CTASection = () => {
    return (
        <section className="cta-section">
            <div className="container">
                <motion.div
                    className="cta-box"
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.5}}
                >
                    <h2>What have you got to lose?</h2>
                    <p>
                        When you only pay when you see results, with no hidden fees, no commitments, no budgets,
                        there's only upside.
                    </p>
                    <p>Take the first step towards bringing your business into the AI-era,
                        revolutionizing aspects your business with a simple, no-pressure conversation.
                    </p>
                    <button className="cta-button"
                            onClick={() => window.open("https://calendly.com/seegreg/zero-introductory-call", "_blank")}
                    >Schedule Chat
                    </button>
                </motion.div>
            </div>
        </section>
    );
};

export default CTASection;