import React from 'react';
import OscillLogo from "./ZeroLogo";

const Header = () => {
    return (
        <header className="header">
            <div className="container">
                <OscillLogo/>
                <nav className="nav">
                    <a href="portfolio">Portfolio</a>
                    <a href="why-zero">Why Zero</a>
                    <a href="chatgpt-software">ChatGPT Software</a>
                    <a href="contact">Contact</a>
                </nav>
            </div>
        </header>
    );
};

export default Header;