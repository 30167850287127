import React from "react";
import "./PortfolioPage.css";

export const PortfolioPage = () => {
    const projects = [
        {
            name: "Oppi",
            url: "oppi.uk",
            description: "Oppi is an AI-Marketing platform, handling email marketing at every stage. " +
                "We fetch, curate, enrich email contact data. We then generate a campaigns for our customers and provide them" +
                "analytics on the performance of their campaigns.",
            image: "/images/oppi-logo.png",
            tags: ["AI", "Marketing", "Email", "Analytics"]
        },
        {
            name: "Catalyst Data Service",
            url: "catalystpf.co.uk",
            description: "We created a service for Catalyst, a UK-based property management company, to fetch and curate high quality marketing, planning and loan data.",
            image: "/images/catalyst-logo.png",
            tags: ["Data", "Analysis", "Marketing"]
        },
        {
            name: "NoteScape",
            url: "notescape.io",
            description: "NoteScape is an AI-powered note-taking app that helps you make and visualise notes faster and more efficiently. ",
            image: "/images/notescape-demo.png",
            tags: ["AI", "Note-taking", "Productivity"]
        }
    ];

    return <div className={"portfolio-page"}>
        <div className="portfolio-container">
            <h1 className="portfolio-title">Our Portfolio</h1>
            <div className="portfolio-grid">
                {projects.map((project, index) => (
                    <div key={index} className="portfolio-item">
                        <div className="portfolio-image">
                            <img src={project.image} alt={project.name}/>
                        </div>
                        <div className="portfolio-content">
                            <h2>{project.name}</h2>
                            <a href={`https://${project.url}`} target="_blank"
                               rel="noopener noreferrer">{project.url}</a>
                            <p>{project.description}</p>
                            <div className="portfolio-tags">
                                {project.tags.map((tag, tagIndex) => (
                                    <span key={tagIndex} className="portfolio-tag">{tag}</span>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
};