import React from 'react';
import { motion } from 'framer-motion';

const processSteps = [
    {
        icon: "/svgs/chat-round-line-svgrepo-com.svg",
        title: "Quick Chat",
        description: "First off we have a quick chat, talk about some of your operations and figure out together how we think they could be improved."
    },
    {
        icon: "/svgs/system-settings-svgrepo-com.svg",
        title: "Propose System",
        description: "Based on our chat we'll go away, do some analysis and design you a prototype of your new software system."
    },
    {
        icon: "/svgs/profit-estimate-compare-decide-choose-svgrepo-com.svg",
        title: "Profit Share",
        description: "We agree on a concrete profit tracking metric, and we take the minority share of the profits generated by proposed system."
    },
    {
        icon: "/svgs/gauge-simple-svgrepo-com.svg",
        title: "Deploy & Improve",
        description: "We'll create the first version of your product and keep iterating on it to make it better and better."
    },
];

const Process = () => {
    return (
        <section className="process">
            <div className="container">
                <h2>Our Process</h2>
                <div className="process-grid">
                    {processSteps.map((item, index) => (
                        <motion.div
                            key={index}
                            className="process-card"
                            whileHover={{y: -5}}
                        >
                            <img src={item.icon} alt={item.title}/>
                            <h3>{item.title}</h3>
                            <p>{item.description}</p>
                        </motion.div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Process;