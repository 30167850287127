import React from "react";
import './ContactPage.css';

const ContactPage = () => {
    return <div className={"contact-page"}>
        <div className="container">
            <section className="contact-section">
                <h1>Contact Us</h1>
                <p>We'd love to hear from you. Choose how you'd like to connect with us:</p>
                <div className="contact-buttons">
                    <a href="mailto:info@zero.com" className="cta-button">
                        Email Us
                    </a>
                    <button onClick={() => window.open('https://calendly.com/zero', '_blank')} className="cta-button">
                        Schedule a Call
                    </button>
                </div>
            </section>
        </div>
    </div>
};

export default ContactPage;