import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import Header from './component/Header';
import Footer from './component/Footer';
import HomePage from './pages/HomePage';
import {PortfolioPage} from "./pages/PortfolioPage";
import {WhyZeroPage} from "./pages/WhyZeroPage";
import ContactPage from "./pages/ContactPage";
import {ChatGPTDevPage} from "./pages/ChatGPTDevPage";

const App = () => {
    return (
        <Router>
            <div className="site-wrapper">
                <Header/>
                <main className="site-content">
                    <Routes>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/portfolio" element={<PortfolioPage/>}/>
                        <Route path="/why-zero" element={<WhyZeroPage/>}/>
                        <Route path="/contact" element={<ContactPage/>}/>
                        <Route path="/chatgpt-software" element={<ChatGPTDevPage/>}/>
                    </Routes>
                </main>
                <Footer/>
            </div>
        </Router>
    );
};

export default App;