import React from 'react';
import Hero from '../component/Hero';
import AIExplainer from '../component/AIExplainer';
import Process from '../component/Process';
import CTASection from '../component/CTASection';

const App = () => {
    return (
        <div className="app">
            <main>
                <Hero/>
                <AIExplainer/>
                <Process/>
                {/*<TestimonialsSection/>*/}
                <CTASection/>
            </main>
        </div>
    );
};

export default App;