import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './ChatGPTDevPage.css';

const pricingData = [
    { quarter: 'Q1 2023', price: 60 },
    { quarter: 'Q3 2023', price: 10 },
    { quarter: 'Q1 2024', price: 5 },
    { quarter: 'Q2 2024', price: 0.15 },
];

export const ChatGPTDevPage = () => {
    return (
        <div className="chatgpt-dev-container">
            <header className="chatgpt-dev-header">
                <h1>How ChatGPT changes business software, the ChatGPT developer</h1>
            </header>
            <main className="chatgpt-dev-content">
                <section className="chatgpt-dev-intro">
                    <h2>Why do tools like ChatGPT change the game</h2>
                    <p>
                        Prior to ChatGPT and similar AI tools, certain parts of software development were very tedious
                        and time consuming. ChatGPT can generate code snippets, provide suggestions, and even help with
                        debugging, significantly speeding up the development process.
                    </p>
                    <p>
                        This is only getting more and more powerful every month, developers are really benefitting at
                        the forefront of these advances.
                    </p>
                    <img src={"/images/chat-gpt-generated-gif.gif"} alt={""}/>
                </section>

                <section className="chatgpt-software-benefits">
                    <h2>Opening new doors in software Development</h2>
                    <p>
                        Alongside allowing developers to work much faster we also need to look into the new use cases
                        that ChatGPT and similar tools enable.
                    </p>
                    <h3>Quick Case Study: Is this a construction company?</h3>
                    <img src="/images/construction-website.png" alt="Example construction website" style={{width: '100%', maxWidth: '900px', height: 'auto', marginBottom: '20px'}} />
                    <p>To the human eye we can very quickly judge if this webpage was for construction company, but it wasn't so
                        simple for old software and if we need to check thousands or millions of pages, humans are
                        simply too slow and expensive.</p>
                    <p>The old software would scan for words like construction, development and building but these words are
                        also used in pages for website designers, Mechanical Engineers, etc.</p>
                    <p>So in order to figure out something as simple as: "is this page a construction company?" it would
                        have taken engineers weeks to develop a reliable algorithm. Costing thousands of pounds in
                        billable hours.</p>
                    <p>The alternative would be to train an AI model for this specific use case, more expensive and even slower for such a simple task.</p>
                    <h4>So How do Tools like ChatGPT change this?</h4>
                    <p>We can simply feed the results into ChatGPT, make sure we have robust testing for let's say 20 construction companies
                        and we're now able to identify them!</p>
                    <p>ChatGPT used to be quite expensive for large amounts of text but that's dropped rapidly, this is the price for 1MM tokens input, a standard measure.</p>
                    <div style={{height: '400px', marginBottom: '20px'}}>
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={pricingData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="quarter" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="price" stroke="#8884d8" activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <p>It's pricing dropped, <strong>400</strong> times in less than a 18 months, not to mention it's 10x faster.</p>
                </section>

                <section className="chatgpt-dev-benefits">
                    <h2>Benefits of ChatGPT in Business Software Development</h2>
                    <ul>
                        <li>Rapid Prototyping: Turn ideas into working prototypes in hours instead of weeks.</li>
                        <li>Cost Efficiency: Significantly reduce development time and resources required.</li>
                        <li>Innovative Solutions: Leverage AI to explore creative approaches to complex business
                            problems.
                        </li>
                        <li>Agile Adaptability: Quickly adjust and refine software as your business needs evolve.</li>
                    </ul>
                </section>

                <section className="chatgpt-dev-reliability">
                    <h2>ChatGPT Sometimes Has Errors: How Can This Be Reliable?</h2>
                    <p>
                        It's true that AI tools like ChatGPT can sometimes produce errors or inconsistencies. However,
                        our approach turns this potential weakness into a strength for your business:
                    </p>
                    <ul>
                        <li>
                            <strong>AI-Assisted, Human-Perfected:</strong> We use ChatGPT to rapidly generate a
                            framework and initial code, dramatically speeding up the development process. However, this
                            is just the starting point.
                        </li>
                        <li>
                            <strong>Expert Oversight:</strong> Our lead developer, with 12 years of coding experience,
                            meticulously reviews and refines every line of AI-generated code. This ensures that the
                            final product not only works flawlessly but also adheres to the highest standards of
                            software development.
                        </li>
                        <li>
                            <strong>Writing human verified tests:</strong> By combining AI's speed and creativity with human
                            expertise and attention to detail, we deliver software that is both innovative and reliable.
                        </li>
                        <li>
                            <strong>Continuous Improvement:</strong> This process allows us to learn from AI
                            suggestions, fostering an environment of continuous improvement and cutting-edge development
                            practices.
                        </li>
                    </ul>
                    <p>
                        The result? Robust, efficient, and innovative software solutions tailored to your specific
                        business needs, delivered in a fraction of the time of traditional development methods.
                    </p>
                </section>

                <section className="chatgpt-dev-future">
                    <h2>Get Ahead of the Curve: Bespoke Software is Coming to Every Business Sooner or Later</h2>
                    <p>
                        ChatGPT and similar AI tools are getting smarter, faster, and cheaper month by month. Every
                        business can benefit from these tools and the earlier they're integrated the more they can
                        expand as the technology continues to rapidly improve.
                    </p>
                    <p>
                        Don't find yourself shocked by the future, become an early adopter. Partner with us to leverage
                        the power of AI in
                        creating bespoke software solutions that will set your business apart and prepare you for the
                        challenges and opportunities ahead.
                    </p>

                    <button className="cta-button"
                            onClick={() => window.open("https://calendly.com/seegreg/zero-introductory-call", "_blank")}
                    >Schedule Chat
                    </button>
                </section>
            </main>
        </div>
    );
};