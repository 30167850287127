import React from "react";
import "./ZeroLogo.css";

const ZeroLogo = () => {
    return (
        <div className="logo-container">
            <a href="/" className="logo">
                <span className="logo-text">Zer</span>
                <span className="logo-o">ø</span>
                <span className="logo-text">dev</span>
            </a>
        </div>
    );
};

export default ZeroLogo;