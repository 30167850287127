import React from 'react';

const Hero = () => {
    return (
        <section className="hero">
            <div className="container flex-row">
                <div className="hero-content">
                    <h1>
                        Bespoke <span className="highlight">AI-Enhanced</span> software systems
                        with <span className="highlight">Zero</span> development costs
                    </h1>
                    <p>
                        We develop a value generating product for you for free, and take a
                        minority share of the generated profits. Sounds good?
                    </p>
                    <button className="cta-button"
                            onClick={() => window.open("https://calendly.com/seegreg/zero-introductory-call", "_blank")}
                    >Schedule Chat
                    </button>
                </div>
                <div className="hero-image">
                    <img src="/images/futuristic-icon.png" alt="AI-Enhanced Software"/>
                </div>
            </div>
        </section>
    );
};

export default Hero;